/**
 * External dependencies
 */
import React, {useEffect} from "react"
import { Col, Form, Row } from "react-bootstrap"

/**
 * Internal dependencies
 */
import Button from "../button/button"
import { useStaticQuery, graphql } from "gatsby"
import SearchResultBoxMenu from "../property-search-bar/SearchResultBoxMenu"
import { navigate } from "@reach/router"
import $ from "jquery";

import "./styles/_index.scss"

const PropertyFind = () => {
  const data = useStaticQuery(graphql`
    query FindQuery {
      glstrapi {
          globalConfig {
            Find_Your_Next_Home {
              Section_Title
              Section_Description
            }
          }
        }
      }     
  `)
  var fetch_data = data.glstrapi.globalConfig.Find_Your_Next_Home;

  useEffect(() => {
    let url = "/"
    $(".property-find_btn-buy").click(function () {
      $(window).scrollTop(0)
      var searcValue = $(".location-search-overlay-menu2 input")
        .val()
        .split(", ")
        .join("-")
        .replace(/ /g, "-")
        .replace(/'/g, "")
        .toLowerCase()
      if (searcValue !== "") {
        url = "in-"+searcValue + "/"
      } else {
        url = "in-sittingbourne/"
      }
      navigate("/property/for-sale/" + url)
    })

    $(".property-find_btn-rent").click(function () {
      $(window).scrollTop(0)
      var searcValue = $(".location-search-overlay-menu2 input")
        .val()
        .split(", ")
        .join("-")
        .replace(/ /g, "-")
        .replace(/'/g, "")
        .toLowerCase()
      if (searcValue !== "") {
        url = "in-"+searcValue + "/"
      } else {
        url = "in-sittingbourne/"
      }
      navigate("/property/to-rent/" + url)
    })
  })

  return (
    <div className="property-find" data-aos="fade-up" data-item="map" id="map">
      <div className="shell">
        <div className="property-find__inner">
          <h2>{fetch_data.Section_Title}</h2>
          <p>{fetch_data.Section_Description}</p>
          <>
            <Row>
              <Col md="7">
                <Form.Group
                  controlId="property-location"
                  className="find-field"
                >
                  <i className="icon-pin-self"></i>
                  <div className="location-search location-search-menu location-search-overlay-menu2 mb-5">
                    <SearchResultBoxMenu placeHolder={`Street, area or location`} />
                  </div>

                  {/* <Form.Control
                    type="text"
                    placeholder="Street, area or location"
                  /> */}
                </Form.Group>
              </Col>
              <Col xs="6" md="auto">
              <button className="btn is-smaller btn--outline-white property-find_btn-buy" >Buy</button>
              </Col>
              <Col xs="6" md="auto">
              <button className="btn is-smaller btn--outline-white property-find_btn-rent">Rent</button>
              </Col>
            </Row>
          </>
        </div>
      </div>
    </div>
  )
}

export default PropertyFind
